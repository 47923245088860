body {
  margin: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden !important;
}
@media (max-width: 320px) {
  body {
    margin: 0px !important;

    overflow-x: hidden !important;
  }
}

@media (max-width: 425px) {
  body {
    margin: 0px !important;

    overflow-x: hidden !important;
    background: blue;
  }
}
@media (max-width: 700px) {
  body {
    margin: 0px !important;

    background: blue;
    overflow-x: hidden !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Gradiante-fourSectionLp {
  background: linear-gradient(71.21deg, #004c97 29.17%, #002855 100%);
}

.MuiTooltip-tooltip {
  background: #fff !important;
  color: #0076cf !important;
  width: 120px !important;
  height: 45px !important;
  display: flex;
  border: none !important;
  border-radius: 20px !important;
  justify-content: center;
  align-items: center;
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  margin-bottom: 20px !important;
}
.MuiTooltip-arrow {
  color: #fff !important;
}
.MuiSlider-markLabel {
  color: #fff !important;
  top: 40px !important;
  font-size: 18px !important;
  font-family: "eliotpro";
  font-weight: 700 !important;
}
.MuiSlider-root {
  height: 15px !important;
}
.MuiSlider-thumb::after {
  width: 45px !important;
  height: 45px !important;
}
