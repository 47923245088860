.mainSection {
  background: linear-gradient(to right, rgba(1, 44, 94, 0.884), rgba(255, 255, 255, 0)),
    url("./../img/FrontPageImage.png") no-repeat center center ;
  background-size: cover;
}

.sectionThree {
  background: linear-gradient(to right, rgba(1, 44, 94, 0.884), rgba(255, 255, 255, 0)),
    url("./../img/MiddleImage.png") no-repeat center center ;
  background-size: cover;
}
.callSection {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(1, 44, 94)), 
    url("./../img/SideRight.png") no-repeat center center;
  background-size: cover;
}

.whatsappSection {
  background: linear-gradient(to right, rgba(1, 44, 94), rgba(255, 255, 255, 0)),
    url("./../img/SideLeft.png") no-repeat center center;
  background-size: cover;
}

.item-wrapper {
  width: 50%;
  justify-content: flex-end;
  display: flex;
}

@media (max-width: 800px) {
  .mainSection {
    background: linear-gradient(rgba(0, 40, 85, 0.4), rgba(0, 40, 85, 0.4)),
      url("./../img/FrontPageImage.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

.mainSectionPerfilador {
  display: flex;
  align-items: center;

  background: linear-gradient(rgba(0, 40, 85, 0.75), rgba(0, 40, 85, 0.75)),
    url("./../img/FrontPageImage.png");
  background-repeat: no-repeat;
  background-position: top -300px right 0px;
  background-size: 1950px 1250px;
}
@media (max-width: 900px){
  .mainSectionPerfilador {
    background: linear-gradient(rgba(0, 40, 85, 0.75), rgba(0, 40, 85, 0.75)),
    url("./../img/FrontPageImage.png") center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 700px) {
  .mainSectionPerfilador {
    background: linear-gradient(rgba(0, 40, 85, 0.4), rgba(0, 40, 85, 0.4)),
      url("./../img/FrontPageImage.png");
    background-repeat: no-repeat;
    /*background-position: top -2px right -272px;
    background-size: 1045px 753px;*/
    background-position: center center;
    background-size: cover;
  }
}
.ContainerRadioInput{
  display: flex;
  flex-direction: row;
   margin-bottom: 10px;
}
.radio-item {
     display: flex;
     flex-direction: row;
     position: relative;
      width: 100%;
   }
   
   .radio-item input[type='checkbox'] {
     display: none;
   }
   
   .radio-item label {
     color: black;
     font-weight: normal;
     display: flex;
 
     
   }

   .radio-item label > b{
   margin-top: 5px;
    
  }
   
   .radio-item label:before {
     content: " ";
     display: inline-block;
     position: relative;
     top: 5px;
     margin: 0 0px 0 0;
     width: 20px;
     height: 20px;
     border-radius: 11px;
     border: 1px solid black;
     background-color: transparent;
   }
   
   .radio-item input[type=checkbox]:checked + label:after {
     border-radius: 11px;
     width: 20px;
     height: 20px;
     position: absolute;
     top: 5px;
    
     content: " ";
     display: block;
     border: 0px ; 
     background: #00EFE8;
    }

    .radio-liq{
      align-items: center;
    align-content: center;
    display: flex;
    }

    .radio-liq input[type='radio'] {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      accent-color: #0061A0;
    }
    .radio-liq label {
      transform: translateY(2px);
      
    }

.selectedStep.accomplished{
  background-color: #00EFE8 !important;
  border: 1px solid #00EFE8 !important
}  

.step-progress-bar{
  height: 30px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.circle-full {
      width: 20px;
      height: 20px;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
      background: #616267;
 }
 .circle-empty {
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: solid 1px #616267;
  background: #ffffff;
}
 .line{
    width: 27px;
    border-top: solid 2px #616267 ;
    transform: translateY(9px);
 }  

 .line-2{
  width: 35px;
  border-top: solid 2px #616267 ;
  transform: translateY(15px);
}

 .hide{
  display:none;
 }
