.flip-card {
  background-color: transparent;
  width: 242px;
  height: 380px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  margin: auto;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  border-radius: 16px;
  box-shadow: -8px 8px 25px rgba(0, 76, 151, 0.2);
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  border-radius: 16px;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #fff;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: #004c97;
  color: white;
  transform: rotateY(180deg);
}
.showBack {
  transform: rotateY(180deg);
}

.card-text-front {
  font-family: "eliotprobold";
  color: #004c97;
  font-size: 22px;
  margin: 30px 0;
}

.card-title-back {
  font-family: "eliotpro";
  color: white;
  font-size: 22px;
  font-weight: 500;
}
.card-text-back {
  font-family: "eliotpro";
  color: white;
  font-size: 15px;
  font-weight: lighter;
}

.card-body {
  align-content: center;
  height: 100%;
  flex-wrap: wrap;
}

.card-body-back {
  height: 87%;
  margin-top: 20px;
  padding: 0 38px;
  flex-wrap: wrap;
}
